import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout location={{pathname : '/404'}}>
    <SEO title="404: Not found" />
    <h1>404: Seite nicht gefunden</h1>
    <p>Sie haben die URL einer Seite eingegeben, die nicht existiert.</p>
  </Layout>
)

export default NotFoundPage